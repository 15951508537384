export const paletteMui = {
    primary: {
        main: '#cd4949',
        contrastText: '#fff'
    },
    secondary: {
        main: '#B0B0B0',
        contrastText: '#fff'
    }
};

export const paletteCustomize = {
    ...paletteMui,
    imgOutline: '#cd4949'
};
