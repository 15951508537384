import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';
import cx from 'classnames';

import useStepper from '@components/Stepper/hook';

import './Stepper.scss';

export default function Stepper({ stepNames, currStep }) {
    const { t } = useTranslation();
    const { currStepIndex, camelStepNames } = useStepper(stepNames, currStep);

    return (
        <Box className="stepper">
            {camelStepNames.map((camelStepName, index) => {
                const isCompletedStep = index < currStepIndex;
                const isCurrIndex = index === currStepIndex;
                // const isLastStep = index === camelStepNames.length - 1;
                return (
                    <Box
                        className={cx([
                            'stepItm',
                            { currStep: isCurrIndex },
                            { completedStep: isCompletedStep }
                        ])}
                        key={camelStepName}>
                        <Box className="stepText">
                            <Box className="stepName">
                                {t('common.step.' + camelStepNames[index])}
                            </Box>
                        </Box>
                    </Box>
                );
            })}
        </Box>
    );
}
